

















import Vue from 'vue'
import {
  ImportWalletMetamask,
  Network,
  Testnet,
  LostKey,
  ViewNFT
} from './_FAQ'
import Glossario from '@/components/FAQ/Glossario.vue'
export default Vue.extend({
  name: 'Home',
  components: {
    ImportWalletMetamask,
    LostKey,
    Network,
    Testnet,
    ViewNFT,
    Glossario
  }
})
