export interface WordDetail{
  word: string;
  definition: string;
}
const wordList: WordDetail[] = [
  {
    word: 'NFT', definition: `Un NFT è una risorsa digitale che rappresenta oggetti del mondo reale come arte, musica, oggetti di gioco e video. Vengono acquistati e venduti online, spesso con le criptovalute.
    NFT sta per "token non fungibile". Quando qualcosa è fungibile, come una banconota da 5 euro, è equivalente e può quindi essere scambiato con qualsiasi altra banconota da un dollaro. Al contrario, un token non fungibile è un bene unico in forma digitale che non può essere scambiato con nessun altro NFT. Ciò significa che ogni NFT è un articolo 'unico'. 
    Sebbene siano in circolazione dal 2014, gli NFT stanno guadagnando notorietà ora perché stanno diventando un modo sempre più popolare per acquistare e vendere opere d'arte digitale. Da novembre 2017 sono stati spesi milioni dollari in NFT.`
  },
  {
    word: 'CryptoStamp', definition: `Il francobollo crittografico è composto da due parti. È un francobollo fisico con una copia digitale corrispondente, una specie di gemello digitale. 
    I francobolli digitali possono essere comuni, mentre altri sono molto più rari e quindi più ambiti`
  },
  {
    word: 'Wallet', definition: `I portafogli di criptovaluta servono ad archiviare le chiavi private, mantenendo la criptovaluta sicura e accessibile.
    Consentono inoltre di inviare, ricevere e spendere criptovalute come Bitcoin ed Ethereum.`
  },
  {
    word: 'Blockchain', definition: `La blockchain è una struttura dati condivisa e "immutabile".
    È definita come un registro digitale le cui voci sono raggruppate in "blocchi", concatenati in ordine cronologico, e la cui integrità è garantita dall'uso della crittografia. Il suo contenuto una volta scritto tramite un processo normato, non è più né modificabile né eliminabile, a meno di non invalidare l'intero processo.`
  },
  {
    word: 'Seed phrase', definition: `Una frase seed è una serie di parole generate dal tuo portafoglio di criptovalute che ti danno accesso alle criptovalute associate a quel portafoglio
    Pensa a un portafoglio come a un gestore di password per le criptovalute, e la frase d'avvio come la password principale.
    Finché hai la tua frase SEED, avrai accesso a tutte le criptovalute associate al portafoglio che ha generato la frase - anche se cancelli o perdi il portafoglio`
  },
  {
    word: 'Ethereum', definition: `Ethereum è una tecnologia che ospita denaro digitale, pagamenti globali e applicazioni.
    Ethereum è una piattaforma basata sulla tecnologia blockchain che è meglio conosciuta per la sua criptovaluta nativa, chiamata ether, o ETH, o semplicemente ethereum. 
    La natura distribuita della tecnologia blockchain è ciò che rende sicura essa.`
  },
  {
    word: 'Polygon', definition: `Polygon è una soluzione di ridimensionamento secondaria per la blockchain di Ethereum.
    Questi tipi di soluzioni funzionano su una rete blockchain esistente anziché utilizzare la propria blockchain. In questo caso, Polygon funziona sopra Ethereum, che è diventato lento e costoso.
    
    Polygon permette transazioni veloci e commissioni basse. MATIC è la criptovaluta nativa della rete, che viene utilizzata per le commissioni oppure per i pagamenti.`
  },
  {
    word: 'Metadato', definition: `I metadati in un NFT sono il contenuto effettivo e la descrizione del contenuto descritto nel contratto memorizzato sulla blockchain.
    In un MP3, la musica è l'evento principale e le informazioni, come l'artista, la band, l'album e i battiti al minuto, sono un'appendice. 
    In alcuni sistemi DRM, anche le informazioni sulla proprietà sono codificate nei metadati.
    
    In un NFT, i metadati sono indicati da un identificatore di risorsa (URI) crittograficamente uniforme a cui si fa riferimento nel token.
    Questo URI potrebbe puntare a un sito Web gestito dal creatore di NFT o da una terza parte, un indirizzo IPFS o un'altra posizione di dati che l'app NFT può risolvere.
    In quasi tutti i casi, i metadati risiedono al di fuori dell'attuale NFT.`
  },
  {
    word: 'IPFS', definition: `IPFS è un sistema distribuito per l'archiviazione e l'accesso a file, siti Web, applicazioni e dati.
    
    Simile al modo in cui le blockchain si affidano ai nodi per contribuire con la loro potenza di calcolo alla verifica dei dati, IPFS ha centinaia di migliaia di nodi singoli sistemi informatici collegati a IPFS, e offrono la loro larghezza di banda di archiviazione per archiviare i file e i contenuti caricati sulla rete.`
  },
  {
    word: 'Metamask', definition: `MetaMask è un'estensione del browser popolare e consolidata che funziona come un portafoglio di criptovaluta che si collega alla blockchain di Ethereum.
    Esso consente agli utenti di interagire con l'ecosistema Ethereum, che ospita un vasto universo di applicazioni decentralizzate, senza dover scaricare l'intera blockchain sul proprio dispositivo`
  }
]
export const charIndex = () : string[] => {
  const uniqueWords: string[] = []
  wordList.map((word) => {
    const char = word.word.toUpperCase().charAt(0)
    if (uniqueWords.includes(char)) return
    uniqueWords.push(char)
  })
  return uniqueWords.sort()
}

export function getWordListFromStart (char: string) : WordDetail[] {
  const filter = wordList.filter((word) => {
    return word.word.toUpperCase().charAt(0) === char
  })
  return filter.sort((a, b) => a.word < b.word ? -1 : 1)
}

export default wordList
