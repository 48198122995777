




















import { charIndex, getWordListFromStart } from './GlossarioTerms'
import Vue from 'vue'
export default Vue.extend({
  name: 'Glossario',
  data: () => ({
    charIndex: charIndex()
    // charIndex: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  }),
  methods: {
    getWordListFromStart: getWordListFromStart,
    generateParagraph (definition: string) : string[] {
      return definition.split('\n')
    }
  }
})
